import { ITrack } from '../../../../api/services/track.service';
import { tagCategories, useTags } from '../../../../hooks/useTags';
import { useEffect, useMemo } from 'react';
import { ITag } from '../../../../api/services/tag.service';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { TagPicker } from './tagTab/TagPicker';

export function TagsTabWindow({
  nextTab,
  activeTrack,
  canBeModified,
}: {
  nextTab: () => void;
  activeTrack: ITrack;
  canBeModified: boolean;
}) {
  const {
    activeTagCategory,
    setActiveTagCategory,
    activeTags,
    setActiveTags,
    setAllTags,
    allTags,
  } = useTags();

  useEffect(() => {
    api.tag
      .getTags()
      .then((response) => {
        setAllTags(response.data.result);
        return api.tag.getTagsByObject(activeTrack.id);
      })
      .then((res) => {
        setActiveTags(res.data.result);
      })
      .catch(() => {
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      });
    // eslint-disable-next-line
  }, [activeTrack]);

  async function removeTagFromTrack(tagId: string) {
    try {
      await api.tag.removeTagFromObject(tagId, activeTrack.id);
    } catch (error) {
      console.error(error);
    }
  }

  async function addTagToTrack(tagId: string) {
    try {
      await api.tag.addTagToObject(tagId, 'track', activeTrack.id);
    } catch (error) {
      console.error(error);
    }
  }

  const curActivaTags = useMemo(
    () =>
      activeTags.filter((tag) => tag.type === activeTagCategory.backendName),
    [activeTagCategory, activeTags]
  );

  const curCategoryTags = useMemo(
    () => allTags.filter((tag) => tag.type === activeTagCategory.backendName),
    [activeTagCategory, allTags]
  );

  function handleTagSelect(tag: ITag) {
    if (!canBeModified) {
      return;
    }
    let index = activeTags.findIndex((tagObj) => tagObj.name === tag.name);
    if (index !== -1) {
      setActiveTags((p) => p.filter((x) => x.name !== tag.name));
      removeTagFromTrack(tag.id);
    } else {
      if (curActivaTags.length < activeTagCategory.maxTags) {
        setActiveTags([...activeTags, tag]);
        addTagToTrack(tag.id);
      } else {
        toast.error('Tags Tag limit reached');
      }
    }
  }

  return (
    <div className='flex flex-grow flex-col pb-10' id='tags-window'>
      <TagPicker
        tagCategory={activeTagCategory}
        activeTags={curActivaTags}
        allTags={curCategoryTags}
        handleTagSelect={handleTagSelect}
      />
      <div className='grid min-h-[88px] w-full grid-cols-6 gap-3'>
        {tagCategories.map((tagCategory, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setActiveTagCategory(tagCategory);
              }}
              className={`animate col-span-1 row-span-1 my-1 flex cursor-pointer items-center rounded-md border-2 border-gray-200 transition-all duration-1000 dark:border-gray-600 dark:hover:bg-slate-600 ${
                activeTagCategory.name === tagCategory.name
                  ? 'dark:bg-slate-600'
                  : ''
              }`}
            >
              <div className='flex w-full flex-col items-center justify-center px-1 '>
                <div className='text-md  font-semibold text-gray-600 dark:text-gray-300'>
                  {tagCategory.name}
                </div>
                <div
                  className={`text-md ml-1 select-none ${
                    activeTags.filter(
                      (tag) => tag.type === tagCategory.backendName
                    ).length >= tagCategory.maxTags
                      ? 'text-red-500'
                      : ''
                  } `}
                >
                  ({' '}
                  {
                    activeTags.filter(
                      (tag) => tag.type === tagCategory.backendName
                    ).length
                  }{' '}
                  / {tagCategory.maxTags} )
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
