import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride';
import { useStudioState } from '../studioState';

import { BsLightbulbFill } from 'react-icons/bs';
import { toast } from 'react-toastify';

const steps: Step[] = [
  {
    title: 'Upload Final Track',
    content: 'Upload the final version of your track without stems.',
    target: '#add-track-btn',
    disableBeacon: true,
  },
  {
    title: 'Upload Stems',
    content: 'Upload your track stems in a zip file.',
    target: '#stem-archive',
    object: 'Submission view /Upload Stems Button in Files Tab ',
    disableBeacon: true,
  },
  {
    title: 'Select General Tab',
    content: "After you've uploaded the stems, move to the General Tab.",
    target: '#General',
    object: 'Submission view /Genral Tab Heading',
    disableBeacon: true,
  },
  {
    title: 'Input Metadata',
    content: 'Provide all the necessary metadata for your track.',
    target: '#track-name',
    object: 'Submission view /General Tab Origial Track Name',
    disableBeacon: true,
  },
  {
    name: 'Navigate to Composer Tab',
    content: 'If all mandatory metadata is provided, move to the composer tab.',
    target: '#Composer',
    object: 'Submission view / Composer Tab heading',
    disableBeacon: true,
  },
  {
    name: 'Add Composers',
    content: 'List all the composers of the track.',
    target: '#add-composer',
    object: 'Submission view / Add composer button in Composer Tab',
    disableBeacon: true,
  },
  {
    name: 'Set Royalty Split',
    content: 'Decide if the royalty split is equal or custom.',
    target: '#royalty-switch-div',
    object: 'Submission view / Royalty split mode toogle button',
    disableBeacon: true,
  },
  {
    name: 'Remember Custom Splits',
    content: 'If custom is activated, ensure splits add up to 100%.',
    target: '#composer-0-royalty',
    object: 'Submission view / royalty split of first composer in list',
    disableBeacon: true,
  },
  {
    name: 'Navigate to Tags Tab',
    content: 'Move to the tags tab to classify your music.',
    object: 'Submission view /Tags Tab Heading',
    target: '#Tags',
    disableBeacon: true,
  },
  {
    name: 'Add Tags',
    content: 'Add relevant tags for each category.',
    object: 'Submission view / Keyword Tag Category Heading',
    target: '#tags-window',
    disableBeacon: true,
  },
  {
    name: 'Add more tracks',
    content:
      'If you want to add more tracks, upload them here and add your metadata, composers and tags.',
    object: 'Submission view / Add Tracks Button',
    target: '#add-track-btn',
    disableBeacon: true,
  },
  {
    name: 'Submit Tracks',
    content: 'After ensuring all details are correct, submit your tracks.',
    object: 'Submission view / Submit Button',
    target: '#submit-project',
    disableBeacon: true,
  },
];

export default function TourStudio() {
  const { user } = useAuth();
  const [stepIndex, setStepIndex] = useState(0);

  const { activeProject, setTourActive, isTourActive } = useStudioState();

  const version = '1.0';
  const tourName = 'tourStudio';

  useEffect(() => {
    if (
      user &&
      window.localStorage.getItem(`${user.id}_${tourName}_${version}`) ===
        null &&
      activeProject
    ) {
      setTourActive(true);
    }
    // eslint-disable-next-line
  }, [user, activeProject]);

  return (
    <>
      <Joyride
        styles={{
          options: {
            primaryColor: '#513DCF',
          },
        }}
        run={isTourActive}
        continuous
        beaconComponent={false}
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
        callback={({ action, index, status, type }: CallBackProps) => {
          // console.log('action', action);
          // console.log('status', status);
          // console.log('type', type);
          // console.log('index', index);

          if (action === ACTIONS.CLOSE) {
            setTourActive(false);
            window.localStorage.setItem(
              `${user?.id}_${tourName}_${version}`,
              'true'
            );
            return;
          }

          if ([EVENTS.STEP_AFTER].includes(type as any)) {
            if (index === 0 || (index === 2 && action === ACTIONS.PREV)) {
              const btn = document.querySelector('#Files');
              if (btn) (btn as HTMLElement).click();
            }
            if (
              (index === 1 && action === ACTIONS.NEXT) ||
              (index === 4 && action === ACTIONS.PREV)
            ) {
              const btn = document.querySelector('#General');
              if (btn) (btn as HTMLElement).click();
            }
            if (
              (index === 3 && action === ACTIONS.NEXT) ||
              (index === 8 && action === ACTIONS.PREV)
            ) {
              const btn = document.querySelector('#Composer');
              if (btn) (btn as HTMLElement).click();
            }
            if (index === 7 && action === ACTIONS.NEXT) {
              const btn = document.querySelector('#Tags');
              if (btn) (btn as HTMLElement).click();
            }
            setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
          }

          if ([EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
            setTourActive(false);
            setStepIndex(0);
            toast.error(
              'Something really went wrong, click quick start again please'
            );
          }

          if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
            setStepIndex(0);
            setTourActive(false);
            window.localStorage.setItem(
              `${user?.id}_${tourName}_${version}`,
              'true'
            );
          }
        }}
      />
      <button
        className='fixed bottom-6 right-6 z-20 flex items-center rounded-full bg-indigo-700 px-4 py-2 font-semibold text-white transition-colors hover:bg-indigo-800'
        onClick={() => {
          prepareOnboarding(stepIndex);
          setTimeout(() => {
            setTourActive(true);
          }, 100);
        }}
      >
        <BsLightbulbFill />
        &nbsp;Quick start
      </button>
    </>
  );
}

const clickFiles = () => {
  clickTab('#Files');
};
const clickGeneral = () => {
  clickTab('#General');
};
const clickComposers = () => {
  clickTab('#Composer');
};
const clickTags = () => {
  clickTab('#Tags');
};

const clickTab = (v: string) => {
  const btn = document.querySelector(v);
  if (btn) (btn as HTMLElement).click();
};

const prepareOnboarding = (step: number) => {
  if (step === 0 || step === 1) {
    clickFiles();
  }
  if (step === 2 || step === 3) {
    clickGeneral();
  }
  if (step > 3 && step < 8) {
    clickComposers();
  }
  if (step === 8 || step === 9) {
    clickTags();
  }
};
