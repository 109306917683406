import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import api from '../api';
import { useLocalStorage } from './useLocalStorage';

import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { ITokenResponse } from '../api/services/auth.service';
import { IFeature } from '../api/services/feature.service';
import { ISubscription } from '../api/services/subscription.service';
import { ITeam } from '../api/services/team.service';

export interface IAuthenticatedUser {
  id: string;
  email: string;
  authProviderId: string;
  firstname: string;
  lastname: string;
  verified: boolean;
  onboarded: boolean;
  authorities: string[];
  isGuest: boolean;
  role: string;
  metadata?: {
    lastActiveTeamId?: string;
  } | null;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: IAuthenticatedUser | null;
  teams: ITeam[];
  activeTeam: ITeam | null;
  logout: () => void;
  handleOAuthCallback: ({
    provider,
    code,
  }: {
    provider: string;
    code: string;
  }) => Promise<ITokenResponse>;
  login: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<boolean>;
  updateUser: () => void;
  setActiveTeam: (teamId: string) => void;
  setAccessToken: (accessToken: string | null) => void;
  accessToken: string | null;
  setRefreshToken: (refreshToken: string | null) => void;

  activeSubscriptions: ISubscription[];
  setActiveSubscriptions: (subs: ISubscription[]) => void;

  activeFeatures: IFeature[];
  isFeatureActive: (featureName: string) => boolean;
  setUser: React.Dispatch<React.SetStateAction<IAuthenticatedUser | null>>;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [accessToken, setAccessToken] = useLocalStorage<string | null>(
    'accessToken',
    null
  );
  const [refreshToken, setRefreshToken] = useLocalStorage<string | null>(
    'refreshToken',
    null
  );

  const [recent, setRecent] = useLocalStorage<any[]>('recentSearches', []);

  const [activeSubscriptions, setActiveSubscriptions] = useState<
    ISubscription[]
  >([]);

  let [user, setUser] = React.useState<IAuthenticatedUser | null>(null);
  let [teams, setTeams] = React.useState<ITeam[]>([]);
  let [activeTeam, setActiveTeam] = React.useState<ITeam | null>(null);
  let [lastActiveTeamId, setLastActiveTeamId] = useLocalStorage<string | null>(
    'lastActiveTeamId',
    null
  );
  let [isAuthenticated, setIsAuthenticated] = React.useState<any>(
    accessToken !== null
  );

  const [activeFeatures, setActiveFeatures] = useState<IFeature[]>([]);

  function isFeatureActive(featureName: string) {
    const isActive =
      activeFeatures.find((feature) => feature.name === featureName) !==
      undefined;
    console.log('isFeatureActive', featureName, activeFeatures, isActive);
    return isActive;
  }

  console.log('-- RERENDER Auth', 'ALG');

  useEffect(() => {
    console.log(
      'UseAuth: useEffect(accessToken) - setIsAuthenticated',
      accessToken !== null
    );
    setIsAuthenticated(accessToken !== null);
    if (accessToken !== null) {
      fetchUser(accessToken);
    }
  }, [accessToken]);

  useEffect(() => {
    console.log('UseAuth: useEffect(lastActiveTeamId)', lastActiveTeamId);
    if (lastActiveTeamId !== null && activeTeam == null) {
      setActiveTeamInternal(lastActiveTeamId);
    }
  }, [lastActiveTeamId, activeTeam]);

  useEffect(() => {
    console.log('++++++++++++++Active team', activeTeam);
    setLastActiveTeamId(activeTeam ? activeTeam.id : null);
  }, [activeTeam]);

  interface JwtPayload {
    authorities: string[];
    uid: string;
  }

  // async function fetchSubscriptions() {
  //   const { data, status } = await api.subscription.getMySubscriptions();
  //   if (status === 200 && data.result) {
  //     setActiveSubscriptions(data.result);
  //   }
  // }

  let fetchUser = async (accessToken: string) => {
    const tokenInfo = jwtDecode<JwtPayload>(accessToken);
    const authorities = tokenInfo.authorities;
    console.log('UseAuth: fetchUser - found user, getting data');
    try {
      const [userResponse, featureResponse, teamsResponse] = await Promise.all([
        api.user.getMyUser(),
        api.feature.getMyFeatures(),
        // api.subscription.getMySubscriptions(),
        api.team.getMyTeams(),
      ]);

      if (featureResponse.status === 200 && featureResponse.data.result) {
        setActiveFeatures(featureResponse.data.result);
      }
      // if (subscriptions.status === 200 && subscriptions.data.result) {
      //   setActiveSubscriptions(subscriptions.data.result);
      // }

      if (teamsResponse.status === 200 && teamsResponse.data.result) {
        const teams = teamsResponse.data.result;
        setTeams(teams);

        const lastTeam = teams.find(
          (team) =>
            team.id === userResponse.data.result.metadata?.lastActiveTeamId
        );

        if (teams.length > 1 && lastTeam !== undefined) {
          setLastActiveTeamId(lastTeam.id);
          setActiveTeam(lastTeam);
        } else {
          if (teams.length > 0) {
            if (lastActiveTeamId) {
              const team = teams.find((team) => team.id === lastActiveTeamId);
              if (team) {
                setActiveTeam(team);
              } else {
                setLastActiveTeamId(teams[0].id || null);
                setActiveTeam(teams[0]);
              }
            } else {
              setLastActiveTeamId(teams[0].id || null);
              setActiveTeam(teams[0]);
            }
          }
        }
      }
      console.log('UseAuth: fetchUser - fetching user data done');
      if (userResponse.status === 200 && userResponse.data.result) {
        const {
          firstname,
          lastname,
          verified,
          id,
          email,
          onboarded,
          authProviderId,
          role,
        } = userResponse.data.result;
        Sentry.setUser({
          email,
          id,
        });
        setUser({
          firstname,
          lastname,
          verified,
          id,
          authorities,
          onboarded,
          authProviderId,
          email,
          isGuest: false,
          role,
        });
      } else {
        console.log(userResponse.data);
        setUser(null);
      }
      // setLoading(false);
      console.log('after loading false');
    } catch (e) {
      console.error(e);
      logout();
    }
  };

  let updateUser = async () => {
    if (isAuthenticated && accessToken) {
      fetchUser(accessToken);
    }
  };

  let logout = async () => {
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    setRecent([]);
    setIsAuthenticated(false);
    setLastActiveTeamId(null);
    setTeams([]);
    setActiveTeam(null);
    Sentry.configureScope((scope) => scope.setUser(null));
  };

  let login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const { data, status } = await api.auth.login(email, password);
    if (status === 200 && data.result?.access_token) {
      setAccessToken(data.result.access_token);
      setRefreshToken(data.result.refresh_token);
      return true;
    } else {
      setUser(null);
      return false;
    }
  };

  let handleOAuthCallback = async ({
    provider,
    code,
  }: {
    provider: string;
    code: string;
  }) => {
    const { data, status } = await api.auth.oAuth(provider, code);
    if (status === 200 && data.result?.access_token) {
      console.log('oauth callback, got token');
      setAccessToken(data.result.access_token);
      setRefreshToken(data.result.refresh_token);
      return data.result;
    } else {
      setUser(null);
      throw new Error('OAuth Error');
    }
  };

  function setActiveTeamInternal(teamId: string) {
    const team = teams.find((team) => team.id === teamId);
    if (teams.length > 0) {
      if (team) {
        setActiveTeam(team);
      } else {
        toast.error('Selected Team not found');
      }
    }
  }

  let value = {
    isAuthenticated,
    handleOAuthCallback,
    logout,
    login,
    user,
    updateUser,
    setAccessToken,
    accessToken,
    setRefreshToken,
    activeSubscriptions,
    setActiveSubscriptions,
    teams,
    activeTeam,
    setActiveTeam: setActiveTeamInternal,
    activeFeatures,
    isFeatureActive,
    setUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
