import copy from 'copy-to-clipboard';
import { FocusEvent, useState } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';

export function UrlCopyElement({ url }: { url: string }) {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) =>
    event.target?.select();

  return (
    <div className='flex overflow-hidden rounded-md border border-slate-400 bg-slate-100 font-mono text-slate-700'>
      <input
        onFocus={handleFocus}
        className='w-full overflow-hidden rounded-md rounded-r-none bg-slate-100 px-3 py-1 font-mono text-sm focus-visible:outline-none'
        value={url}
        onChange={() => {}}
      />
      <div
        onClick={() => {
          setIsCopied(true);
          copy(url);
          setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        }}
        className='flex cursor-pointer items-center justify-center border-l border-slate-400 py-1 px-4 hover:bg-slate-200'
      >
        {!isCopied && <FaCopy size={15} />}
        {isCopied && <FaCheck size={15} className='text-emerald-500' />}
      </div>
    </div>
  );
}

export function UrlCopyAndActionElement({
  url,
  action,
  actionIcon,
}: {
  url: string;
  action: () => void;
  actionIcon: JSX.Element;
}) {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) =>
    event.target?.select();

  return (
    <div className='flex overflow-hidden rounded-md border border-slate-400 bg-slate-100 font-mono text-slate-700'>
      <input
        onFocus={handleFocus}
        className='w-full overflow-hidden rounded-md rounded-r-none bg-slate-100 px-3 py-1 font-mono text-sm focus-visible:outline-none'
        value={url}
        onChange={() => {}}
      />
      <div
        onClick={() => {
          setIsCopied(true);
          copy(url);
          setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        }}
        className='flex cursor-pointer items-center justify-center border-l border-slate-400 py-1 px-4 hover:bg-slate-200'
      >
        {!isCopied && <FaCopy size={15} />}
        {isCopied && <FaCheck size={15} className='text-emerald-500' />}
      </div>
      <div
        onClick={action}
        className='flex cursor-pointer items-center justify-center border-l border-slate-400 py-1 px-4 hover:bg-slate-200'
      >
        {!isCopied && actionIcon}
        {isCopied && <FaCheck size={15} className='text-emerald-500' />}
      </div>
    </div>
  );
}
