import axios from 'axios';
import {
  ChangeEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FaArchive, FaFolderPlus, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../../api';
import { IFile } from '../../../api/services/file.service';
import { ITrack } from '../../../api/services/track.service';
import { useAuth } from '../../../hooks/useAuth';
import { downloadFile } from '../../../utils/fileHelper';
import DeleteConfirmationDialog from '../../shared/dialog/DeleteConfirmationDialog';
import TextEditDialog from '../../shared/dialog/TextEditDialog';
import { useAlbumStudioState } from '../albumState';
import TrackListRow from './trackList/TrackListRow';
import { MdOutlineFileUpload } from 'react-icons/md';
import { Tooltip } from 'flowbite-react';
import { Mapping } from '../../../config/types';
import Loader from '../../shared/Loader';
import TrackListDialog from '../dialogs/TrackListDialog';

function StudioFileListInternal() {
  const {
    activeTrack,
    setActiveTrack,
    activeFiles,
    activeFilesLoading,
    activeAlbumTracks,
    activeAlbumTracksLoading,
    activeAlbum,
    refreshData,
  } = useAlbumStudioState();

  const { activeTeam } = useAuth();

  const [isUploadingMasteredFiles, setIsUploadingMasteredFiles] =
    useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [objectToBeRenamed, setObjectToBeRenamed] = useState<
    ((IFile | ITrack) & { type: string }) | null
  >(null);

  const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);
  const [deleteTrackData, setDeleteTrackData] = useState<{
    title: string;
    targetName: string;
    id: string;
  }>();
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (activeAlbumTracks.length > 0) {
      if (activeTrack === null) {
        setActiveTrack(activeAlbumTracks[0]);
      } else {
        const newTrackData = activeAlbumTracks.find(
          (track) => track.id === activeTrack.id
        );
        if (newTrackData) {
          setActiveTrack(newTrackData);
        }
      }
    }
    // eslint-disable-next-line
  }, [activeAlbumTracks, activeTrack]);

  async function renameItem(id: string, type: any, props: { name: string }) {
    try {
      if (type === 'track') {
        await api.track.updateTrackPartially(id, props);
        refreshData('activeProjectTracks');
        toast.success('Track renamed successfully');
      } else {
        toast.warn("You can't rename that");
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (type === 'track' && e.response?.status === 409) {
          return toast.error('Track with the same name already exists');
        }
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  const closeDeleteTrackModal = useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);

  const confirmDeleteTrack = async () => {
    if (!activeAlbum || !deleteTrackData?.id) return;
    try {
      setIsDeleting(true);
      await api.album.deleteTrackFromAlbum(activeAlbum.id, deleteTrackData.id);
      refreshData('activeProjectTracks');
      toast.success('Track deleted successfully');
      setIsDeleteDialogOpen(false);
    } catch (error) {
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const onRenameCancel = useCallback(() => {
    setIsRenameDialogOpen(false);
    setObjectToBeRenamed(null);
  }, []);

  const onRenameSubmit = useCallback(
    (newValue: string) => {
      if (objectToBeRenamed) {
        renameItem(objectToBeRenamed.id, objectToBeRenamed.type, {
          name: newValue,
        });
      }
      setIsRenameDialogOpen(false);
      setObjectToBeRenamed(null);
    },
    // eslint-disable-next-line
    [objectToBeRenamed]
  );

  const tracksMap = useMemo(
    () =>
      activeAlbumTracks.reduce((acc: Mapping<ITrack>, track) => {
        acc[track.name] = track;
        return acc;
      }, {}),
    [activeAlbumTracks]
  );

  const onSelectUploadMasteredFiles: ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    if (e.target.files === null || !activeAlbum) return;
    const files = Array.from(e.target.files);

    const filesToUpload: {
      track: ITrack;
      file: File;
    }[] = [];

    for (const file of files) {
      if (file.name.split('.').slice(-1)[0] !== 'wav') {
        toast.error(`File should be wav: ${file.name}`);
        continue;
      }

      const trackName = getTrackNameFromFilename(file.name) || '';
      const track = tracksMap[trackName];

      if (!track) {
        toast.error(`Filename doesn't match to any track: ${file.name}`);
        continue;
      }

      const validName = [
        activeAlbum.metadata.albumCode,
        track.metadata.artistName,
        track.name,
        'mastered',
      ]
        .filter((v) => !!v)
        .join('_');

      if (file.name.split('.').slice(0, -1).join('.') !== validName) {
        toast.error(`Filename doesn't match to any track: ${file.name}`);
        continue;
      }

      filesToUpload.push({
        track,
        file,
      });
    }

    try {
      setIsUploadingMasteredFiles(true);
      const filesResArr = await Promise.all(
        filesToUpload.map(({ file, track }) => {
          return api.file.addFile({
            name: file.name,
            size: file.size,
            parentId: track.id,
            contentType: file.type,
            projectId: track.projectId,
            tenantId: activeAlbum.teamId,
            role: 'MASTER',
          });
        })
      );

      const trackFiles = filesResArr.map((r) => r.data.result);

      const urls = await Promise.all(
        trackFiles.map(async (f) => api.file.getFilePresignedUploadUrl(f.id))
      );

      await Promise.all(
        filesToUpload.map((f, i) => {
          const url = urls[i].data.result!.presignedUrl;
          return axios.put(url, f.file, {
            headers: {
              'Content-Type': f.file.type,
            },
          });
        })
      );

      await Promise.all(
        trackFiles.map((file, i) => {
          const track = filesToUpload[i].track;
          return api.track.updateTrackPartially(file.parentId, {
            metadata: {
              ...track.metadata,
              masterStatus: 'mastered',
            },
          });
        })
      );

      refreshData('activeProjectTracks');

      toast.success(
        <span className='whitespace-pre-line'>
          {'Files uploaded successfully for:\n'}
          {activeAlbumTracks
            .filter((t) => trackFiles.some((f) => f.parentId === t.id))
            .map((t) => t.name)
            .join('\n')}
        </span>
      );
      setIsUploadingMasteredFiles(false);
    } catch (error) {
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
      setIsUploadingMasteredFiles(false);
    }
  };

  return (
    <div className='flex grow flex-col rounded-2xl bg-white shadow-xl dark:bg-slate-900'>
      <DeleteConfirmationDialog
        isOpen={isDeleteDialogOpen}
        title={deleteTrackData?.title || ''}
        message={
          <>
            Do you really want to delete{' '}
            <span className='font-semibold'>
              "{deleteTrackData?.targetName || ''}"
            </span>{' '}
            ?
          </>
        }
        close={closeDeleteTrackModal}
        onSubmit={confirmDeleteTrack}
        isLoading={isDeleting}
      />
      <TextEditDialog
        isOpen={isRenameDialogOpen}
        title={'Rename'}
        initialValue={`${objectToBeRenamed ? objectToBeRenamed?.name : ''}`}
        onCancel={onRenameCancel}
        onSubmit={onRenameSubmit}
      />
      <TrackListDialog
        isOpen={isTrackModalOpen}
        setIsOpen={setIsTrackModalOpen}
      />
      {/* Card header */}
      <div className='flex items-center justify-between rounded-t-2xl border-b border-gray-200 bg-gray-50 p-2 px-4 dark:border-gray-600 dark:bg-gray-800'>
        <div className='flex flex-grow items-center'>
          <div className='mr-10 flex items-center bg-gray-50 dark:bg-gray-800'>
            <div className='flex'>
              <FaArchive size={16} className='text-indigo-700' />
            </div>
            <div className='ml-2 flex text-lg font-medium leading-6 text-slate-700 dark:text-slate-200'>
              Track
            </div>
          </div>
        </div>
        <div className='flex space-x-2'>
          <div>
            {isUploadingMasteredFiles ? (
              <Loader className='!static !h-4 !w-4 bg-transparent !text-indigo-700 ' />
            ) : (
              <Tooltip content='Upload master files'>
                <label className='cursor-pointer text-indigo-600'>
                  <MdOutlineFileUpload size={16} />
                  <input
                    multiple
                    accept='.wav'
                    key={Date.now()}
                    type='file'
                    onChange={onSelectUploadMasteredFiles}
                    className='hidden'
                  />
                </label>
              </Tooltip>
            )}
          </div>
          <div
            className='cursor-pointer text-indigo-600'
            onClick={() => setIsTrackModalOpen(true)}
            data-testid='track-add-button'
          >
            <FaFolderPlus size={16} />
          </div>
        </div>
      </div>
      <div className='scroll MiniScrollbar flex max-h-64 flex-col divide-y overflow-auto md:max-h-full md:grow md:basis-0'>
        {!activeFilesLoading &&
          activeAlbumTracks
            .sort((a, b) => +a.metadata.trackNumber - +b.metadata.trackNumber)
            .map((track) => {
              return (
                <TrackListRow
                  downloadFile={(file) =>
                    downloadFile(
                      file,
                      track,
                      activeAlbum,
                      activeTeam,
                      `${activeAlbum?.metadata.albumCode}-${
                        track.metadata.trackNumber
                      }_${track.isrc ? `${track.isrc}_` : ''}${track.name}_${
                        activeTeam?.name
                      }${
                        track.metadata.masterStatus === 'mastered'
                          ? ''
                          : `_${track.metadata.masterStatus || 'draft'}`
                      }.wav`
                    )
                  }
                  onRename={() => {
                    setObjectToBeRenamed({ ...track, type: 'track' });
                    setIsRenameDialogOpen(true);
                  }}
                  onDelete={() => {
                    setDeleteTrackData({
                      title: `Delete track from album`,
                      targetName: `${activeTrack?.name || ''}`,
                      id: track.id,
                    });
                    setIsDeleteDialogOpen(true);
                  }}
                  track={track}
                  isActive={track.id === activeTrack?.id}
                  file={
                    activeFiles.find(
                      (file) =>
                        file.parentId === track.id && file.role === 'MAIN'
                    ) || null
                  }
                  key={track.id}
                  showDelete={true}
                  onSelected={() => {
                    setActiveTrack(track);
                  }}
                  deleteOptionLabel='Remove from Album'
                />
              );
            })}
        {activeAlbumTracksLoading && (
          <div className='flex items-center justify-center p-20 text-slate-500'>
            <FaSpinner size={30} className='animate-spin' />
          </div>
        )}
        {!activeAlbumTracksLoading && activeAlbumTracks.length === 0 && (
          <div className='flex flex-col items-center justify-center p-8 text-slate-500'>
            <div className='mb-2 font-semibold text-slate-500'>
              No tracks yet
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
const StudioFileList = memo(StudioFileListInternal);
export default StudioFileList;

const getTrackNameFromFilename = (filename: string) => {
  const parts = filename.split('_').slice(0, -1);
  return parts.pop();
};
