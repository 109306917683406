import { FaArrowRight } from 'react-icons/fa';
import { IProject } from '../../api/services/project.service';
import { globalThemeStyles } from '../../config/globalStyles';
import StatusBadge from '../shared/StatusBadge';

// project Card Component
export const ProjectCard = ({
  project,
  action,
}: {
  project: IProject;
  action: () => void;
}) => (
  <div
    key={project.id}
    className={`col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow ${globalThemeStyles.box}`}
  >
    <div className='flex w-full items-center justify-between space-x-2 rounded-lg p-6 '>
      {/* <div>
      <FaUserCircle
        className='h-10 w-10 rounded-full bg-white'
        color={stringToColor(
          project.members.filter((member) => member.role === 'GUEST')[0].user
            .firstname
        )}
      /></div> */}
      <div className='flex-1 truncate'>
        <div className='flex items-center space-x-3'>
          <h3 className='truncate text-base font-semibold '>{project.name}</h3>
        </div>
        {/* <p className='mt-1 truncate text-sm text-gray-500'>To be submitted by: </p> */}
      </div>
      {/* <div className='flex-1 truncate'>
        <div className='flex items-center space-x-3'>
          <h3 className='truncate text-sm font-medium text-gray-900'>
          {project.name}
          </h3>
        </div>
        <p className='mt-1 truncate text-sm text-gray-500'>To be submitted by: </p>
      </div> */}
      <StatusBadge status={project.status} />
    </div>
    <div>
      <div className='-mt-px flex divide-x divide-gray-200'>
        {/* {(project.status === 'REQUESTED' || project.status === 'SUBMITTED') && (
          <div className='flex w-0 flex-1'>
            <a
              href={`mailto:${project.id}`}
              className='relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold '
            >
              <BiMailSend
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
              Contact
            </a>
          </div>
        )} */}
        <div className='-ml-px flex w-0 flex-1'>
          {project.status === 'REQUESTED' && (
            <span
              onClick={action}
              className='relative inline-flex w-0 flex-1 cursor-pointer items-center justify-center gap-x-3 rounded-b-lg border border-transparent bg-teal-500 py-4 text-sm font-semibold  text-white hover:bg-teal-600'
            >
              Submit tracks
              <FaArrowRight
                className='h-5 w-5 text-gray-400 text-white'
                aria-hidden='true'
              />
            </span>
          )}
          {project.status === 'DONE' && (
            <span
              onClick={action}
              className='relative inline-flex w-0 flex-1 cursor-pointer items-center justify-center gap-x-3 rounded-b-lg border border-transparent bg-emerald-500 py-4 text-sm font-semibold  text-white hover:bg-emerald-600'
            >
              Accepted by label
              {/* <FaEye
                className='h-5 w-5 text-gray-400 text-white'
                aria-hidden='true'
              /> */}
            </span>
          )}
          {project.status === 'SUBMITTED' && (
            <a className='relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-b-lg border border-transparent bg-stone-500 py-4 text-sm font-semibold  text-white'>
              Pending review by label
            </a>
          )}
        </div>
      </div>
    </div>
  </div>
);
