import { IResponse } from '..';
import client from '../client';

export interface ITag {
  id: string;
  teamId: string;
  name: string;
  type: string;
  createdBy?: string;
}

export interface INewTag {
  teamId: string;
  name: string;
  type: string;
}

export interface ITagMapping {
  tagId: string;
  mappedObjectId: string;
  mappedObjectType: string;

  createdAt?: string;
  createdBy?: string;
}

async function getTags() {
  return client.get<IResponse<ITag[]>>(`/tag`);
}

async function addTag(tag: INewTag) {
  return client.post<IResponse<ITag>>(`/tag`, tag);
}

async function deleteTag(id: string) {
  return client.delete<IResponse<ITag>>(`/tag/${id}`);
}

async function getTagsByObject(objectId: string) {
  return client.get<IResponse<ITag[]>>(`/tag/mappedObject:${objectId}`);
}

async function addTagToObject(
  tagId: string,
  objectType: string,
  objectId: string
) {
  return client.post<IResponse<ITagMapping>>(`/tag/${tagId}/mappedObject`, {
    mappedObjectId: objectId,
    mappedObjectType: objectType,
    tagId,
  });
}

async function removeTagFromObject(tagId: string, objectId: string) {
  return client.delete<IResponse<ITagMapping>>(
    `/tag/${tagId}/mappedObject/${objectId}`
  );
}

async function updateTagPartially(tagId: string, tag: Partial<ITag>) {
  return client.patch<IResponse<ITag>>(`/tag/${tagId}`, tag);
}

const api = {
  getTags,
  getTagsByObject,
  addTag,
  deleteTag,
  addTagToObject,
  removeTagFromObject,
  updateTagPartially,
};

export default api;
