import axios from 'axios';
import { KeyboardEvent, useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { KTRLogo } from '../../components/shared/ktr';
import { useAuth } from '../../hooks/useAuth';

export default function PasswordResetExecute() {
  const { isAuthenticated, user } = useAuth();
  let navigate = useNavigate();
  let { token } = useParams();

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/', { replace: true });
    }
  });

  useEffect(() => {
    if (token) {
      verifyToken(token);
    }
  }, [token]);

  async function verifyToken(token: string) {
    try {
      setLoading(true);
      const success = await api.auth.verifyPwResetToken(token);
      setTokenValid(success.status === 200);
      setLoading(false);
    } catch (e) {
      setTokenValid(false);
      setLoading(false);
    }
  }

  async function requestPwReset() {
    try {
      if (
        token &&
        tokenValid &&
        password.length >= 8 &&
        password === password2
      ) {
        setError(false);
        setLoading(true);
        const success = await api.auth.submitPwReset(token, password);
        setLoading(false);
        if (success) {
          setCompleted(true);
          toast.success(
            'Your password has been reset, please continue to login'
          );
        } else {
          toast.error('Error on password reset');
        }
      } else {
        toast.error('Your password does not meet our requirements');
        setError(true);
      }
    } catch (e) {
      setLoading(false);
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  function handleEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      requestPwReset();
    }
  }

  return (
    <div className='mt-20 flex flex-grow'>
      <div className='flex w-full flex-col items-center'>
        <div className='flex flex-col items-center'>
          <div className='h-40 w-40'>
            <KTRLogo />
          </div>
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
            Reset your password
          </h2>
        </div>
        <div className='mt-8 w-full max-w-md rounded-md bg-white px-8 py-8 pt-1 shadow'>
          {completed && (
            <p className='mt-6  text-center text-gray-600 '>
              Your password has been reset successfully! <br />
              Head over{' '}
              <Link
                className='font-medium text-indigo-600 hover:text-indigo-500'
                to='/login'
              >
                here
              </Link>{' '}
              to log in to your account
            </p>
          )}
          {loading && (
            <div className='flex items-center justify-center p-20 text-slate-500'>
              <FaSpinner size={30} className='animate-spin' />
            </div>
          )}
          {!loading && tokenValid && !completed && (
            <div className='mt-6 space-y-5 rounded-md shadow-sm'>
              <p className='mt-2 text-sm text-gray-600'>
                Pick a new strong password consisting of at least 8 characters
              </p>
              <div>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium text-gray-700'
                >
                  Password
                </label>
                <div className='mt-1'>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                    placeholder=''
                    onChange={(password) => setPassword(password.target.value)}
                    defaultValue={password}
                    onKeyDown={handleEnter}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor='password2'
                  className='block text-sm font-medium text-gray-700'
                >
                  Reenter Password
                </label>
                <div className='mt-1'>
                  <input
                    id='password2'
                    name='passwor2'
                    type='password'
                    autoComplete='current-password'
                    required
                    className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                    placeholder=''
                    onChange={(password2) =>
                      setPassword2(password2.target.value)
                    }
                    defaultValue={password2}
                    onKeyDown={handleEnter}
                  />
                </div>
              </div>
            </div>
          )}
          {!loading && tokenValid && !completed && (
            <div className='mt-6'>
              <button
                type='submit'
                className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                onClick={() => requestPwReset()}
              >
                Reset Password
              </button>
            </div>
          )}
          {!loading && !tokenValid && !completed && (
            <div className='mt-6 space-y-5 rounded-md '>
              <div>
                <p className='mt-2 text-center text-gray-600'>
                  The Password reset link you used seems to be expired. You can
                  request a new one{' '}
                  <Link
                    className='font-medium text-indigo-600 hover:text-indigo-500'
                    to='/resetPassword'
                  >
                    here
                  </Link>
                </p>
              </div>
              <div className='mt-6 flex justify-between'>
                <div className=''>
                  <p className='text-slate-700'>Don't have an account?</p>
                </div>
                <Link
                  className='font-medium text-indigo-600 hover:text-indigo-500'
                  to='/signUp'
                >
                  Sign up
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
