import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface GoogleAdsParams {
  // utm_term?: string;
  // utm_campaign?: string;
  // utm_source?: string;
  // utm_medium?: string;
  // hsa_acc?: string;
  // hsa_cam?: string;
  // hsa_grp?: string;
  // hsa_ad?: string;
  // hsa_src?: string;
  // hsa_tgt?: string;
  // hsa_kw?: string;
  // hsa_mt?: string;
  // hsa_net?: string;
  // hsa_ver?: string;
  // gclid?: string;
  [k: string]: string;
}

export default function useAdParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [adParams, setAdParams] = useState<GoogleAdsParams>({});

  useEffect(() => {
    const params: GoogleAdsParams = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    if (adParams !== params) {
      setAdParams(params);
    }
  }, [searchParams]);

  return {
    adParams,
  };
}
