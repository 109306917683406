import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import useAdParams, { GoogleAdsParams } from '../../hooks/useAdParams';
import { useLocalStorage } from '../../hooks/useLocalStorage';

import AppleLogo from './apple-logo.svg';
import GoogleLogo from './google-logo.svg';
import MicrosoftLogo from './microsoft-logo.svg';

type LocationProps = {
  state: {
    from: Location;
  };
};

export default function OAuthBar() {
  let location = useLocation() as LocationProps;

  let { adParams } = useAdParams();

  const [fromLocation, setFromLocation] = useLocalStorage<string | null>(
    'fromLocation',
    null
  );

  const [adParamsLocal, setAdParamsLocal] =
    useLocalStorage<GoogleAdsParams | null>('adParams', null);

  async function oAuthRedirect(provider: string) {
    try {
      // setLoading(true);
      const { data, status } = await api.auth.getOAuthURL(provider, null);
      // setLoading(false);
      if (status === 200) {
        setAdParamsLocal(adParams);
        if (location.state && location.state.from) {
          setFromLocation(location.state.from.pathname);
        }
        window.open(data.result?.targetUrl, '_self');
      } else {
        toast.error('Error on OAuth redirect');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error('Something really went wrong!');
      }
    }
  }

  return (
    <div className='mx-5 mt-2 flex space-x-3'>
      <button
        type='submit'
        className='flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-100 disabled:text-gray-500'
        onClick={() => oAuthRedirect('o365')}
      >
        <img className='h-5' src={MicrosoftLogo} />
      </button>
      {/* <button
        type="submit"
        className="w-full flex justify-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => oAuthRedirect("github")}
      >
        <FaGithub size={20} />
      </button> */}
      <button
        type='submit'
        className='flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        onClick={() => oAuthRedirect('google')}
      >
        <img className='h-5' src={GoogleLogo} />
      </button>
      {/* <button
        type="submit"
        disabled
        className="w-full flex justify-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-100 disabled:text-gray-500"
        onClick={() => oAuthRedirect("facebook")}
      >
        <FaFacebook size={20} />
      </button> */}
      <button
        type='submit'
        className='flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        onClick={() => oAuthRedirect('apple')}
      >
        <img className='h-5' src={AppleLogo} />
      </button>
    </div>
  );
}
