import {
  ExclamationTriangleIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { toast } from 'react-toastify';
import api from '../../api';
import { IBanner } from '../../api/services/banner.service';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { splitArray } from '../../utils';

export function Banner() {
  const [selectedBanner, setSelectedBanner] = useState<IBanner | null>(null);
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [bannersSeen, setBannerSeen] = useLocalStorage<string[]>(
    'bannersSeen',
    []
  );

  useEffect(() => {
    if (banners.length > 0 && bannersSeen) {
      const bannerToDisplay = getBannerToDisplay(banners, bannersSeen);
      setSelectedBanner(bannerToDisplay);
    }
  }, [banners, bannersSeen]);

  function getBannerToDisplay(banners: IBanner[], bannersSeen: string[]) {
    const now = new Date();
    const [validBanners, expiredBanners] = splitArray(
      banners,
      (b) => new Date(b.validUntil) > now
    );
    // TODO: delete expired bannerIds from local storage
    const unseenBanners = validBanners.filter(
      (b) => bannersSeen.indexOf(b.id) === -1
    );
    return unseenBanners[0];
  }

  useEffect(() => {
    fetchBanners();
  }, []);

  async function fetchBanners() {
    try {
      const { data } = await api.banner.getBanners();
      if (data.result) {
        setBanners(data.result);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  function setLocalBannerStatus(id: string) {
    setBannerSeen([...bannersSeen, id]);
  }

  const bannerStyle = {
    info: {
      bg: 'bg-blue-500 font-semibold text-white',
      iconBg: 'bg-blue-800',
      icon: <MegaphoneIcon className='h-6 w-6 text-white' />,
    },
    warning: {
      bg: 'bg-yellow-300 text-slate-700 font-semibold',
      iconBg: 'bg-amber-800',
      icon: <ExclamationTriangleIcon className='h-6 w-6 text-white' />,
    },
  };

  return (
    <>
      {selectedBanner && (
        <div className='flex w-full items-center justify-center'>
          <div
            className={`text- my-2 mx-8 flex w-full max-w-1920 items-center justify-between rounded-xl ${
              bannerStyle[selectedBanner.type].bg
            } py-3 px-4`}
          >
            <div className='flex items-center justify-start '>
              <div
                className={`mr-2 flex rounded-lg ${
                  bannerStyle[selectedBanner.type].iconBg
                } p-2`}
              >
                {bannerStyle[selectedBanner.type].icon}
              </div>
              <div>
                <ReactMarkdown>{selectedBanner.message}</ReactMarkdown>
              </div>
            </div>
            <div
              className='cursor-pointer pr-3 text-white'
              onClick={() => setLocalBannerStatus(selectedBanner.id)}
            >
              <FaTimes />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
