import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../api';
import { IProject } from '../../../api/services/project.service';
import { ITrack } from '../../../api/services/track.service';
import useDebounce from '../../../hooks/useDebounce';

export const useTracks = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [trackList, setTrackList] = useState<ITrack[]>([]);
  // const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 200);
  // const [totalTracksFound, setTotalTracksFound] = useState(0);
  const [projectFilter, setProjectFilter] = useState<IProject | undefined>();
  const [BPMFilter, setBPMFilter] = useState<number | undefined>();
  // const [catalogueFilter, setCatalogueFilter] = useState<string | undefined>();
  const [keynoteFilter, setKeynoteFilter] = useState<string | undefined>();
  const [artistFilter, setArtistFilter] = useState<string | undefined>();
  const [scaleTypeFilter, setScaleTypeFilter] = useState<string | undefined>();

  useEffect(() => {
    searchTracks(debouncedQuery);
    // eslint-disable-next-line
  }, [debouncedQuery]);

  useEffect(() => {
    api.project
      .searchProject({ size: 10000 })
      .then((res) => {
        setProjects(res.data.result?.content || []);
      })
      .catch(console.error);
  }, []);

  const resetFilters = () => {
    setProjectFilter(undefined);
    setBPMFilter(undefined);
    // setCatalogueFilter(undefined);
    setKeynoteFilter(undefined);
    setArtistFilter(undefined);
    setScaleTypeFilter(undefined);
  };

  async function searchTracks(query: string) {
    try {
      // setLoading(true);
      const response = await api.track.searchTrack({
        condition: {
          type: 'eq',
          key: 'name',
          value: query,
        },
        size: 10000,
      });
      setTrackList([...(response.data.result?.content || [])]);
      // setLoading(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.error(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  const BPMOPtions = useMemo(() => {
    return trackList
      .map((track) => (track.metadata?.bpm ? +track.metadata.bpm : undefined))
      .filter((v) => v !== undefined)
      .filter((v, i, self) => self.indexOf(v) === i);
  }, [trackList]);

  // const catalogues = useMemo(() => {
  //   return trackList
  //     .map((track) =>
  //       track.metadata?.catalogue ? track.metadata.catalogue : undefined
  //     )
  //     .filter((v) => !!v)
  //     .filter((v, i, self) => self.indexOf(v) === i);
  // }, [trackList]);

  const keynotes = useMemo(() => {
    return trackList
      .map((track) => (track.metadata?.key ? track.metadata.key : undefined))
      .filter((v) => !!v)
      .filter((v, i, self) => self.indexOf(v) === i);
  }, [trackList]);

  const artists = useMemo(() => {
    return trackList
      .map((track) =>
        track.metadata?.artistName ? track.metadata.artistName : undefined
      )
      .filter((v) => !!v)
      .filter((v, i, self) => self.indexOf(v) === i);
  }, [trackList]);

  const scaleTypes = useMemo(() => {
    return trackList
      .map((track) =>
        track.metadata?.scaleType ? track.metadata.scaleType : undefined
      )
      .filter((v) => !!v)
      .filter((v, i, self) => self.indexOf(v) === i);
  }, [trackList]);

  const filteredTracks = useMemo(() => {
    let result = trackList;
    if (projectFilter) {
      result = result.filter((v) => v.projectId === projectFilter.id);
    }
    if (artistFilter) {
      result = result.filter((v) =>
        v.metadata?.artistName ? v.metadata.artistName === artistFilter : false
      );
    }
    if (BPMFilter) {
      result = result.filter((v) =>
        v.metadata?.bpm ? +v.metadata.bpm === BPMFilter : false
      );
    }
    // if (catalogueFilter) {
    //   result = result.filter((v) =>
    //     v.metadata?.catalogue ? v.metadata.catalogue === catalogueFilter : false
    //   );
    // }
    if (keynoteFilter) {
      result = result.filter((v) =>
        v.metadata?.key ? v.metadata.key === keynoteFilter : false
      );
    }
    if (scaleTypeFilter) {
      result = result.filter((v) =>
        v.metadata?.scaleType ? v.metadata.scaleType === scaleTypeFilter : false
      );
    }

    return result;
  }, [
    projectFilter,
    trackList,
    BPMFilter,
    // catalogueFilter,
    keynoteFilter,
    artistFilter,
    scaleTypeFilter,
  ]);
  return {
    resetFilters,
    projects,
    setQuery,
    projectFilter,
    trackList,
    BPMFilter,
    // catalogueFilter,
    keynoteFilter,
    artistFilter,
    scaleTypeFilter,
    // catalogues,
    keynotes,
    artists,
    scaleTypes,
    filteredTracks,
    setProjectFilter,
    setBPMFilter,
    // setCatalogueFilter,
    setKeynoteFilter,
    setArtistFilter,
    setScaleTypeFilter,
    BPMOPtions,
    query,
  };
};
