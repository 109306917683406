import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactElement } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { classNames } from '../../../utils';

export default function DeleteConfirmationDialog({
  title,
  message,
  isOpen,
  close,
  onSubmit,
  isLoading,
}: {
  title: string;
  message: ReactElement;
  isOpen: boolean;
  close: () => void;
  onSubmit: () => void;
  isLoading: boolean;
}) {
  const onClose = () => {
    if (isLoading) return;
    close();
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className={classNames(
            `relative z-10`,
            isLoading ? 'cursor-not-allowed' : ''
          )}
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-xl transform rounded-lg bg-white py-4 px-4 text-left align-middle shadow-xl transition-all dark:bg-gray-800 lg:py-6 lg:px-6'>
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center text-xl font-semibold leading-6 text-slate-700 dark:text-slate-200'>
                      <span>{title}</span>
                    </div>
                  </div>
                  <div className='mt-5 space-y-6 text-slate-700 dark:text-slate-200'>
                    {message}
                  </div>
                  <div className='mt-4 flex justify-end space-x-4'>
                    <button
                      className=' rounded p-2 px-4 font-semibold text-slate-600 hover:bg-slate-200 disabled:opacity-50 dark:text-slate-300 dark:hover:bg-slate-700'
                      onClick={onClose}
                      disabled={isLoading}
                    >
                      Cancel
                    </button>
                    <button
                      className='flex items-center gap-x-2 rounded bg-rose-600 p-2 px-4 font-semibold text-white hover:bg-rose-700 disabled:opacity-50'
                      onClick={onSubmit}
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <FaSpinner size={16} className='animate-spin' />
                      )}
                      <span>OK</span>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
