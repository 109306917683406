type TagCategoryType =
  | 'MOOD'
  | 'STYLE'
  | 'MUSICFOR'
  | 'INSTRUMENT'
  | 'ELEMENT'
  | 'KEYWORD'
  | 'CATALOGUE';

export const getTagsForCSVExport = (type: 'tags' | 'albumTags', category: TagCategoryType) => (rowObject: any) => {
  const keys = Object.keys(rowObject);
  return keys
    .filter(key => key.indexOf(`${type}.${category}`) > -1 && key.indexOf('name') > -1)
    .map(key => rowObject[key])
    .join(',');
};
