import { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FaFileImport, FaTrash, FaUserCircle } from 'react-icons/fa';
import stringToColor from 'string-to-color';

import axios from 'axios';
import { DateTime } from 'luxon';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import api from '../../../api';
import { IProject, IUserShort } from '../../../api/services/project.service';
import { globalThemeStyles } from '../../../config/globalStyles';
import { usePrevious } from '../../../hooks/usePrevious';
import DeleteConfirmationDialog from '../../shared/dialog/DeleteConfirmationDialog';
import StatusBadge from '../../shared/StatusBadge';
import ProjectMembersDialog from '../dialogs/ProjectMembersDialog';
import { useStudioState } from '../studioState';

export default function SubmissionInfo() {
  const [isModalOpenMember, setIsModalOpenMember] = useState(false);
  const navigate = useNavigate();
  const {
    activeProject,
    setActiveProject,
    activeProjectRole,
    activeProjectMembers,
    activeProjectInvites,
    refreshData,
  } = useStudioState();

  // editable fields
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const prevDueDate = usePrevious(dueDate);
  const [title, setTitle] = useState('');
  // const [description, setDescription] = useState('');
  const [artist, setArtist] = useState<IUserShort | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // load global state initially
  useEffect(() => {
    if (activeProject) {
      if (activeProject.name !== title) {
        setTitle(activeProject.name);
      }
      if (!datesEqual(activeProject.dueDate, dueDate)) {
        setDueDate(
          activeProject.dueDate !== null
            ? new Date(activeProject.dueDate)
            : null
        );
      }
    }
  }, [activeProject]);

  // sync back input updates to global state
  useEffect(() => {
    if (
      activeProject &&
      activeProject.dueDate !== undefined &&
      prevDueDate !== undefined &&
      prevDueDate !== dueDate
    ) {
      if (activeProject.dueDate !== null) {
        if (!datesEqual(activeProject.dueDate, dueDate)) {
          setActiveProject({
            ...activeProject,
            dueDate:
              dueDate !== null
                ? DateTime.fromMillis(dueDate.getTime()).toISO()
                : null,
          });
        }
      } else {
        if (dueDate !== activeProject.dueDate) {
          setActiveProject({
            ...activeProject,
            dueDate: DateTime.fromMillis(dueDate.getTime()).toISO(),
          });
        }
      }
    }
  }, [dueDate]);

  // sync back input updates to global state
  useEffect(() => {
    if (activeProject && title !== activeProject.name && title.length > 0) {
      setActiveProject({ ...activeProject, name: title });
    }
  }, [title]);

  function datesEqual(stringDate: string | null, date: Date | null) {
    if (stringDate === null && date !== null) {
      return false;
    }
    if (stringDate !== null && date === null) {
      return false;
    }
    if (stringDate === null && date === null) {
      return true;
    }
    if (stringDate !== null && date !== null) {
      if (new Date(stringDate).getTime() !== new Date(date).getTime()) {
        return false;
      }
    }
    return true;
  }

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [objectToBeDeleted, setObjectToBeDeleted] = useState<IProject | null>(
    null
  );

  function onDelete() {
    setObjectToBeDeleted(activeProject);
    setIsDeleteDialogOpen(true);
  }

  // load project members and invites and pick artist
  useEffect(() => {
    const artistMember = activeProjectMembers.find((x) => !isPublisher(x.role));
    const artistInvite = activeProjectInvites.find(
      (x) => !isPublisher(x.projectRole)
    );
    if (artistMember != null) {
      setArtist(artistMember.user);
    } else if (artistInvite != null) {
      setArtist({
        email: artistInvite.targetUserEmail,
        id: artistInvite.targetUserId,
        lastname: '',
        avatar: '',
        firstname: '',
      });
    } else {
      setArtist(null);
    }
  }, [activeProjectMembers, activeProjectInvites]);

  // function !isPublisher(role: string | null) {
  //   return role === "GUEST";
  // }

  function isPublisher(role: string | null) {
    return role === 'OWNER';
  }

  function isRoleLoaded(role: string | null) {
    return role !== null && role !== '' && role !== undefined;
  }

  function ArtistInput() {
    return (
      <div>
        <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
          Artist
        </label>
        <div
          onClick={() => {
            if (
              isPublisher(activeProjectRole) &&
              activeProject?.status === 'DRAFT'
            ) {
              setIsModalOpenMember(true);
            }
          }}
          className={`${
            isPublisher(activeProjectRole)
              ? 'cursor-pointer border shadow-sm' + globalThemeStyles.inputbox
              : ''
          } mt-1 flex items-center p-1 px-2 sm:text-sm`}
          style={{ height: '38px' }}
          data-testid='submission-artist-btn'
        >
          <div className='flex-shrink-0'>
            <FaUserCircle
              className='h-6 w-6 rounded-full bg-white'
              color={artist ? stringToColor(artist?.id) : 'gray'}
            />
          </div>
          <div className='ml-2'>
            <div className='font-medium text-gray-800 dark:text-gray-200'>
              {artist?.firstname} {artist?.lastname}
              {artist == null && (
                <span className='text-sm text-gray-500'>
                  No artist selected
                </span>
              )}
            </div>
            <div className='text-sm font-medium text-gray-500'>
              {artist?.email} {artist?.firstname ? '' : artist ? '(OPEN)' : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function capitalize(string: string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  const onDeleteCancel = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setObjectToBeDeleted(null);
  }, []);

  const onDeleteSubmit = useCallback(() => {
    setIsDeleteDialogOpen(false);
    if (objectToBeDeleted) {
      deleteItem(objectToBeDeleted?.id);
      setObjectToBeDeleted(null);
      // TODO: also delete file??
    }
  }, [objectToBeDeleted]);

  async function deleteItem(id: string) {
    try {
      // if (type === "file") {
      //   const response = await api.file.deleteFile(id);
      //   if (response.status === 204) {
      //     fetchFiles(project.id);
      //     toast.success("File deleted successfully");
      //   }
      // TODO: handle file deletion?
      setIsDeleting(true);
      const response = await api.project.deleteProject(id);
      setIsDeleting(false);
      if (response.status === 204) {
        navigate('/');
        toast.success('Submission deleted successfully');
      }
    } catch (e) {
      setIsDeleting(false);
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  function DueDateInput() {
    return (
      <div>
        <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
          Submission due
        </label>
        <div className='mt-1 flex'>
          {isRoleLoaded(activeProjectRole) &&
            !isPublisher(activeProjectRole) &&
            dueDate && (
              <div className='block w-full flex-1'>
                <span className='text-lg font-medium text-gray-700 dark:text-gray-200'>
                  {activeProject?.dueDate
                    ? DateTime.fromISO(activeProject?.dueDate).toFormat(
                        'yyyy-MM-dd'
                      )
                    : 'Not set'}
                </span>
              </div>
            )}
          {isRoleLoaded(activeProjectRole) &&
            isPublisher(activeProjectRole) && (
              <div className='flex w-full rounded-lg'>
                <ReactDatePicker
                  selected={dueDate}
                  onChange={(date) => setDueDate(date)}
                  dateFormat='yyyy-MM-dd'
                  minDate={new Date()}
                  className={`border-1 block w-full flex-1 shadow-sm sm:text-sm ${globalThemeStyles.inputbox}`}
                  id='submissionDue'
                />
              </div>
            )}
          {!isRoleLoaded(activeProjectRole) && (
            <div className='block w-full flex-1'>
              <span className='text-lg font-medium text-gray-700 dark:text-gray-200'></span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col rounded-2xl bg-gray-50 shadow-xl dark:bg-gray-800'>
      <ProjectMembersDialog
        isOpen={isModalOpenMember}
        setIsOpen={setIsModalOpenMember}
        projectMembers={activeProjectMembers}
        projectInvites={activeProjectInvites}
        fetchProjectInvites={() => refreshData('activeProjectInvites')}
        fetchProjectMembers={() => refreshData('activeProjectMembers')}
      />
      <DeleteConfirmationDialog
        isLoading={isDeleting}
        isOpen={isDeleteDialogOpen}
        title={`Delete ${objectToBeDeleted && capitalize('project')}`}
        message={
          <>
            Do you really want to delete{' '}
            <span className='font-semibold'>
              "{objectToBeDeleted && objectToBeDeleted.name}"
            </span>{' '}
            ?
          </>
        }
        close={onDeleteCancel}
        onSubmit={onDeleteSubmit}
      />
      <div className='flex items-center justify-between border-b p-2 px-4 dark:border-gray-600'>
        <div className='flex flex-grow items-center'>
          <div className='mr-10 flex items-center rounded-lg'>
            <div className='flex'>
              <FaFileImport size={16} className='text-indigo-700' />
            </div>
            <div className='ml-2 flex text-lg font-medium leading-6 text-slate-700 dark:text-slate-200'>
              Submission
            </div>
          </div>
        </div>
        <div className='flex space-x-2'>
          {/* TODO: Activate in next version */}

          {isPublisher(activeProjectRole) && (
            <button
              className='group flex w-full items-center rounded-md pl-3 text-sm'
              onClick={onDelete}
            >
              <FaTrash
                className='mr-2 h-5 w-5 hover:scale-110'
                aria-hidden='true'
              />
            </button>
          )}
          {activeProject && (
            <StatusBadge
              status={activeProject?.status}
              testid='submission'
              // className={"bg-amber-600 text-white"}
            />
          )}
        </div>
      </div>
      <div className='grid w-full grid-cols-3 gap-6 space-y-4 rounded-b-2xl bg-white p-2 px-4 pb-4 dark:bg-gray-900 md:space-y-0'>
        <>
          <div className='space-y-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700 dark:text-gray-200'>
                Submission name
              </label>
              <div className='mt-1 flex'>
                {isRoleLoaded(activeProjectRole) &&
                  !isPublisher(activeProjectRole) && (
                    <div className='block w-full flex-1'>
                      <span className='text-lg font-medium text-gray-700 dark:text-gray-200'>
                        {title}
                      </span>
                    </div>
                  )}
                {isRoleLoaded(activeProjectRole) &&
                  isPublisher(activeProjectRole) && (
                    <input
                      onChange={(title) => setTitle(title.target.value)}
                      value={title}
                      type='text'
                      className={`block w-full flex-1 shadow-sm sm:text-sm ${globalThemeStyles.inputbox}`}
                      // className="shadow-sm block w-full flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder='Title of your project'
                    />
                  )}
                {!isRoleLoaded(activeProjectRole) && (
                  <div className='block w-full flex-1'>
                    <span className='text-lg font-medium text-gray-700 dark:text-gray-200'></span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-between space-y-6'>
            <ArtistInput />
          </div>
          <div className='flex flex-col justify-between space-y-6'>
            <DueDateInput />
          </div>
        </>
      </div>
    </div>
  );
}
