import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { useAuth } from '../../hooks/useAuth';
// import "../tours/tour.css";

export default function CheckoutCallback() {
  const { user } = useAuth();

  const [checkoutSessionStatus, setCheckoutSessionStatus] = useState<
    string | null
  >('');

  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const status = params.status;
    const checkoutSessionId = searchParams.get('session_id');
    if (status) {
      if (status === 'success' && checkoutSessionId) {
        verifyCheckoutStatus(checkoutSessionId);
      }
    }
  }, [params, searchParams]);

  async function verifyCheckoutStatus(checkoutSessionId: string) {
    try {
      setLoading(true);
      const { data } = await api.subscription.searchSubscription({
        condition: {
          key: 'latestCheckoutId',
          type: 'eq',
          value: checkoutSessionId,
        },
      });
      if (data.result) {
        if (data.result.content.length === 1) {
          setCheckoutSessionStatus('success');
        } else {
          // no checkout found
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <div className='flex grow flex-col items-center'>
      {checkoutSessionStatus && (
        <div className='mt-20 flex flex-col items-center space-y-12'>
          <h1 className='mt-2 text-center text-3xl font-bold text-emerald-600'>
            Thank you for your purchase, {user ? user.firstname : ''}!
          </h1>
          <div
            onClick={() => navigate('/')}
            className='relative mb-2 mt-20 flex w-80 cursor-pointer justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          >
            Back to Dashboard
          </div>
        </div>
      )}
      {!checkoutSessionStatus && (
        <div className='mt-20 flex flex-col items-center space-y-12'>
          <h1 className='mt-2 text-center text-3xl font-bold text-slate-700'>
            Error
          </h1>
          <h2 className='mt-2 text-center text-xl font-bold text-slate-700'>
            It seems like something went wrong while processing your
            subscription, please contact our support to complete your purchase.
          </h2>
          {/* <p>We are sorry for your inconvenience</p> */}
          <div className='relative mb-2 mt-20 flex w-80 cursor-pointer justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
            <a href='https://ktr.studio/contact'>Contact support</a>
          </div>
        </div>
      )}
    </div>
  );
}
